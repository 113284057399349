import { graphql } from "gatsby"
import Layout from "../../../components/layout/LayoutCareer"
import React from "react"
const Careers = (data) => {
  const jobs = data.data.allContentfulJobs.nodes

  return <Layout jobs={jobs}>&nbsp;</Layout>
}

export default Careers

export const query = graphql`
  query MyQuery {
    allContentfulJobs {
      nodes {
        id
        title
        role
        category
        slug
      }
    }
  }
`
