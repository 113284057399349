import React from "react"
const HeaderSub = () => {
  return (
    <section
      className="elementor-element elementor-element-4b45b7c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-id="4b45b7c"
      data-element_type="section"
      data-settings='{"background_background":"classic"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-aaf3640 elementor-column elementor-col-50 elementor-top-column"
            data-id="aaf3640"
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-fddc123 elementor-icon-list--layout-inline elementor-mobile-align-center elementor-widget elementor-widget-icon-list"
                  data-id="fddc123"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="icon-list.default"
                >
                  <div className="elementor-widget-container">
                    <ul className="elementor-icon-list-items elementor-inline-items">
                      <li
                        className="elementor-icon-list-item"
                        id="padding-icon-telp"
                      >
                        <a href="tel:(+6221)4265310">
                          <span className="elementor-icon-list-icon">
                            <i
                              aria-hidden="true"
                              className="fas fa-phone-alt"
                            />
                          </span>
                          <span className="elementor-icon-list-text">
                            (+6221) 426 5310
                          </span>
                        </a>
                      </li>
                      <li className="elementor-icon-list-item">
                        <a href="mailto:info@pharmametriclabs.com">
                          <span className="elementor-icon-list-icon">
                            <i aria-hidden="true" className="far fa-envelope" />
                          </span>
                          <span className="elementor-icon-list-text">
                            info@pharmametriclabs.com
                          </span>
                        </a>
                      </li>
                      {/* <li className="elementor-icon-list-item">
												<span className="elementor-icon-list-icon">
													<i aria-hidden="true" className="far fa-clock" />
												</span>
												<span className="elementor-icon-list-text">Mon-Fri 8am - 6pm</span>
											</li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeaderSub
